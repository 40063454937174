import React, { useEffect, useState } from "react";
import { Loading } from "../../common/path.ts";
import { insertTicketOrderData } from "../../../api/insert-payment-data.ts";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WEBNAME } from "../../../constant/AppConstants.ts";
import QRCode from "react-qr-code";
import { CloudArrowDownFill } from "react-bootstrap-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const PaymentSuccess = () => {
  const [loading, setLoading] = useState(false);
  const [qrDataUrl, setQrDataUrl] = useState("");

  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  const payment_intent = new URLSearchParams(window.location.search).get(
    "payment_intent"
  );

  const redirect_status = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );

  if (redirect_status !== "succeeded") {
    window.location.replace("/");
  }

  if (!clientSecret) {
    window.location.replace("/");
  }

  function removePrefix(input: any): any {
    return input.replace(/^pi_/, "pi_");
  }

  const userDataString: any = sessionStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  const ticketDataString: any = sessionStorage.getItem("cartItems");
  const ticketData = JSON.parse(ticketDataString);

  const downloadMsg = () =>
    toast.success("Download Your QR Code...", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const qrDownloading = () =>
    toast.success("Downloading..", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const dataSet: any = await insertTicketOrderData(
          clientSecret,
          payment_intent,
          userData,
          ticketData
        );

        if (dataSet.isDone) {
          sessionStorage.removeItem("userData");
          sessionStorage.removeItem("cartItems");
          downloadMsg();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (ticketData && ticketData.length > 0 && userData) {
      fetchData();
    }
  }, []);

  const handleDownloadPDF = () => {
    const pdf = new jsPDF();
    const qrElement = document.getElementById("qr-code");

    if (qrElement) {
      html2canvas(qrElement).then((canvas) => {
        const imageData = canvas.toDataURL("image/png");
        const qrAspectRatio = canvas.width / canvas.height;
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        let imageSize = pdfWidth * 1; // Set initial size based on PDF width
        let x = (pdfWidth - imageSize) / 2;
        let y = (pdfHeight - imageSize / qrAspectRatio) / 2;

        // Adjust size and position if the height exceeds the PDF height
        if (imageSize / qrAspectRatio > pdfHeight) {
          imageSize = pdfHeight * 0.8;
          y = (pdfHeight - imageSize * qrAspectRatio) / 2;
          x = (pdfWidth - imageSize) / 2;
        }

        pdf.addImage(
          imageData,
          "PNG",
          x,
          y,
          imageSize,
          imageSize / qrAspectRatio
        );
        pdf.save(`${WEBNAME}_qrcode.pdf`);
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="container mx-auto">
            <div className="row d-flex justify-content align-items-center mt-4">
              <p className="text-center fs-4 text-light">
                Thank you for your ticket purchase. Your Order is confirmed.{" "}
                <br /> Your ticket can be collected at the event gate on the
                scheduled date.
              </p>
              <div
                id="qr-code"
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                <QRCode
                  value={removePrefix(payment_intent)}
                  size={256}
                  onError={(err) => console.error(err)}
                />
                <button
                  className="btn-sm btn border border-1 border-light text-light px-3 mt-2"
                  onClick={() => {
                    qrDownloading();
                    handleDownloadPDF();
                  }}
                >
                  <CloudArrowDownFill /> Download PDF
                </button>
              </div>
              <p className="text-center mt-0">
                Please keep this code confidential. <br /> We have sent the QR
                code to the email and phone number you provided
              </p>
            </div>
            <section className="my-5 text-">
              <ul>
                <li>⚠ Important Guidelines ⚠</li>
                <li>✅ Dress Code: Pure white attire is mandatory.</li>
                <li>
                  ❌ No Outside Items: Bringing food, drinks, or alcohol is
                  strictly prohibited.
                </li>
                <li>
                  ❌ Security First: Weapons of any kind are not allowed inside.
                </li>
                <li>
                  🚫 Zero Tolerance: The use or possession of drugs is strictly
                  forbidden.
                </li>
                <li>
                  🤝 Respect Others: Avoid any behavior that may disturb others,
                  especially women.
                </li>
                <li>
                  🚨 Security Check: If you leave the event premises, inform
                  security beforehand.
                </li>
              </ul>
            </section>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
};
