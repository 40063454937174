import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Element } from "react-scroll";
import { IMAGE_URL, WEBNAME } from "../../../../constant/AppConstants.ts";
import { Loading } from "../../../common/path.ts";

export const HeroSection = () => {
  const [loading, setLoading] = useState(false);
  const [logoImageSrc, setLogoImageSrc] = useState<any>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const logo = await `${IMAGE_URL}/logo.png`;
        setLogoImageSrc(logo);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClick = (path: string) => {
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    } else {
      const element = document.getElementById(path);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Element name="home" className="element" id="home">
          <section className="hero-wrap section-1-bg-img" data-aos="fade-up">
            <div className="overlay"></div>
            <div className="container">
              <div className="row slider-text align-items-center justify-content-center">
                <div className="col-lg-7 d-flex align-items-center">
                  <div className="text text-center">
                    <img
                      src={logoImageSrc}
                      className="img-fluid col-4"
                      alt="logo"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    />{" "}
                    <br />
                    <h1
                      className="logo"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <Link to={"/"}>{WEBNAME}</Link>
                    </h1>
                    <h1
                      className="mb-4 fs-1"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Mastering Music <br /> Chilling Vibes
                    </h1>
                    <p
                      className="mb-4 mx-5 "
                      data-aos="fade-up"
                      data-aos-delay="600"
                    >
                      Maxwin Events: Elevating experiences through seamless
                      planning and captivating videography. Your vision, our
                      expertise, unforgettable moments every time.
                    </p>
                    <p
                      className=" border border-1 border-light w-25 mx-auto py-2"
                      style={{ cursor: "pointer" }}
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      <Link to={"next-event?id=12356"}>Buy Tickets</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Element>
      )}
    </>
  );
};
