import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchAdminDashBoardData,
  insertEventData,
} from "../../../../api/path.ts";
import { Loading } from "../../../common/path.ts";

export const AddEvent = () => {
  //auth manage
  const navigate = useNavigate();
  const userString = sessionStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : { role: "" };
  const role = user.role;

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [date, setDate] = useState<any>();
  const [eventUrl, setEventUrl] = useState<any>();
  const [ticketUrl, setTicketUrl] = useState<any>();
  const [isEventDataUploadBtnDisable, setIsEventDataUploadBtnDisable] =
    useState(false);

  const [eventPoster, setEventPoster] = useState<any>();
  const [image1, setImage1] = useState<any>();
  const [image2, setImage2] = useState<any>();

  const handleUpload = async () => {
    setIsEventDataUploadBtnDisable(true);
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("date", date);
      formData.append("eventUrl", eventUrl);
      formData.append("ticketUrl", ticketUrl);
      //formData.append("eventPoster", eventPoster);
      // formData.append("image2", image1[0]);
      // formData.append("image3", image2[0]);

      const dataSet = await insertEventData(formData);

      console.log(dataSet);
      console.log("data");
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      // Re-enable the upload button
      setLoading(false);
      setIsEventDataUploadBtnDisable(false);
    }
  };

  useEffect(() => {
    if (!userString || role !== "admin") {
      navigate("/admin/sign-in");
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="row">
            <h2 className="ms-3 my-3">Add Event Details</h2>

            <div className="d-flex">
              <div className="col-md-6 col-12 ms-3">
                <div className="form-outline mb-1">
                  <label className="form-label">Event Title</label>
                  <span className="text-danger fs-5">*</span>
                  <input
                    type="email"
                    className={"form-control"}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>

                <div className="form-outline mb-1">
                  <label className="form-label">Description</label>
                  <span className="text-danger fs-5">*</span>
                  <textarea
                    rows={10}
                    className="form-control"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>

                <div className="form-outline mb-1">
                  <label className="form-label">Event Date</label>
                  <span className="text-danger fs-5">*</span>
                  <input
                    type="text"
                    className={"form-control"}
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12 ms-3">
                <div className="form-outline mb-1">
                  <div className="form-outline mb-1">
                    <label className="form-label">
                      Event Location (Google Map URL)
                    </label>
                    <span className="text-danger fs-5">*</span>
                    <input
                      type="text"
                      className={"form-control"}
                      value={eventUrl}
                      onChange={(e) => {
                        setEventUrl(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-outline mb-1">
                  <label className="form-label">
                    Ticket Location (Google Map URL)
                  </label>
                  <input
                    type="text"
                    className={"form-control"}
                    value={ticketUrl}
                    onChange={(e) => {
                      setTicketUrl(e.target.value);
                    }}
                  />
                </div>

                <div className="col-6">
                  <label htmlFor="image1">
                    Event Poster <span className="text-danger fs-5">*</span>{" "}
                  </label>
                  <input
                    type="file"
                    id="image1"
                    name="image1"
                    accept="image/webp"
                    onChange={(e) => {
                      const file = e.target.files && e.target.files[0];
                      if (file) {
                        setEventPoster(file);
                      }
                    }}
                  />
                  <label htmlFor="image2" className="mt-2">
                    Event Image 1 <span className="text-danger fs-5">*</span>
                  </label>
                  <input
                    type="file"
                    id="image2"
                    name="image2"
                    accept="image/webp"
                    onChange={(e) => {
                      setImage1(e.target.files);
                    }}
                  />
                  <label htmlFor="image3" className="mt-2">
                    Event Image 2 <span className="text-danger fs-5">*</span>
                  </label>
                  <input
                    type="file"
                    id="image3"
                    name="image3"
                    accept="image/webp"
                    onChange={(e) => {
                      setImage2(e.target.files);
                    }}
                  />
                </div>
                <button
                  className="btn btn-danger mt-3 w-75"
                  // disabled={!title || !description || !date || !eventUrl || !eventPoster || !image1 || !image2}
                  onClick={handleUpload}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <h2 className="ms-3 my-3">Add Ticket details</h2>
          </div>
        </>
      )}
    </>
  );
};
