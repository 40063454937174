// config.js
const config = {
    webName: "Maxwin",

    facebook: "https://web.facebook.com/profile.php?id=61552629834709",
    instergram:"#",
    youtube:"#",
    tiktok:"https://www.tiktok.com/@officialmaxwin",

    address:"Yokohama, Kanagawa, Japan",
    mobile:"+818072411994",
    email: "maxwinevents@gmail.com",
    whatsapp:"+818072411994",
    googleMap:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12945.585678933063!2d140.3223453!3d35.7902002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60228b197d4ef397%3A0x37bfcbcb3fbd81f2!2sNarita%20International%20Culture%20Hall!5e0!3m2!1sen!2slk!4v1708799170554!5m2!1sen!2slk",

    webLink:"https://maxwin.events",
    server:'https://maxwin.events/server',
    authReq:'maxwin-auth',
    imagepath:'https://maxwin.events/images',
    currency:'¥',

    // webLink:"http://localhost:3000",
    // server:'http://localhost/app/maxwin/server',
    // authReq:'maxwin-auth',
    // imagepath:'http://localhost/app/maxwin/images',
    // currency:'¥',

    //paymentgatway
    publickAPI:'pk_live_51OnKHPHfvoGbulSffieI4ek5kmycAhJxJCx78w1MXbe1GZEru7knP5Mb8DBnTZHTzAZvQdroKg3X7zCauvu3tAeN00IMGC3m9m',
    //publickAPI:'pk_test_51OnKHPHfvoGbulSfgvlTsLcqtkyJ1eyj96tMss2m9fDbqH457U8L9uVReMiLmgtvbREboWSdOy5EJvdqX8rruQxY00siBUu59u',
    countryCode:'+94',
    devsSite:"https://taprodev.com/"
  };
  
  export default config;
  