import React, { useEffect, useState } from "react";
import ImageModal from "../../common/image-modal/ImageMdal.tsx";
import { Link, useLocation } from "react-router-dom";
import { fetchNextEventPageData } from "../../../api/path.ts";
import { Loading } from "../../common/path.ts";
import { useInView } from "react-intersection-observer";
import { IMAGE_URL } from "../../../constant/AppConstants.ts";

export const NextEventPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [eventData, setEventData] = useState<any>();
  const [mainImageSrc, setMainImageSrc] = useState<any>("");
  const [ticketImages, setTicketImages] = useState<any>([]);
  const [artistImages, setArtistImages] = useState<any>([]);
  const [sponsorImages, setSponsorImages] = useState<any>([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageCount, setImageCount] = useState(10);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const handleImageClick = (imageUrl, name, price, note) => {
    setSelectedImage(imageUrl);
    setSelectedName(name);
    setSelectedPrice(price);
    setSelectedNote(note);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
    setSelectedName(null);
    setSelectedPrice(null);
  };

  const fetchData = async () => {
    try {
      if (id) {
        setLoading(true);
        const dataSet = await fetchNextEventPageData(parseInt(id));
        setEventData(dataSet);

        const imageModuleMain =
          await `${IMAGE_URL}/${dataSet.EventData.mainImage}`;
        setMainImageSrc(imageModuleMain);

        //ticket image
        const ticketImagesArray = await Promise.all(
          eventData.ticket.map(async (data: any) => {
            const imageModule = await `${IMAGE_URL}/${data.image}`;
            return imageModule;
          })
        );
        setTicketImages(ticketImagesArray);

        //artist image array
        const artistImagesArray = await Promise.all(
          eventData.artist.map(async (data: any) => {
            const imageModule = await `${IMAGE_URL}/${data.image}`;
            return imageModule;
          })
        );
        setArtistImages(artistImagesArray);

        //sponsor image array
        const sponsorImagesArray = await Promise.all(
          eventData.sponsor.map(async (data: any) => {
            const imageModule = await `${IMAGE_URL}/${data.image}`;
            return imageModule;
          })
        );
        setSponsorImages(sponsorImagesArray);

        //gallery image
        const importedImages: any = await Promise.all(
          eventData.galleryImage.slice(0, imageCount).map(async (index) => {
            try {
              const imgModule = await `${IMAGE_URL}/${index}`;
              return imgModule;
            } catch (error) {
              console.error("Error loading image:", error);
              return null;
            }
          })
        );

        // Filter out any null values that occurred due to errors
        setGalleryImages(importedImages.filter((image) => image !== null));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location.search, imageCount]);

  useEffect(() => {
    {
      loading &&
        (ticketImages.length === 0 ||
          artistImages.length === 0 ||
          sponsorImages.length === 0) &&
        fetchData();
    }
  });

  useEffect(() => {
    if (inView) {
      setImageCount((prevCount) => prevCount + 10);
    }
  }, [inView]);

  return (
    <>
      {loading &&
      ticketImages.length === 0 &&
      artistImages.length === 0 &&
      sponsorImages.length === 0 ? (
        <Loading />
      ) : eventData && ticketImages.length != 0 && artistImages.length != 0 ? (
        <section className="img pt-3" id="nextEvent">
          <div className="container">
            <div className="row d-flex ">
              <div
                className="col-md-6 col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="img-about section-2-bg-img img d-flex align-items-stretch">
                  <div className="overlay"></div>
                  <div className="img img-video d-flex align-self-stretch align-items-center ">
                    <img src={mainImageSrc} className="w-100" alt="" />
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-6 pl-md-5"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                <div className="heading-section">
                  <h2
                    className="my-4 text-uppercase"
                    data-aos="fade-down"
                    data-aos-delay="400"
                  >
                    {eventData.EventData.title}
                  </h2>
                  <p data-aos="fade-down" data-aos-delay="500">
                    {eventData.EventData.subTitle}
                  </p>

                  <div>
                    {eventData.isBookingAvailable ? (
                      <>
                        {/* <p
                          className="fs-3 fw-bold text-start text-light"
                          data-aos="fade-down"
                          data-aos-delay="650"
                        >
                          <span className="number">
                            {eventData.EventData.availableTicket}
                          </span>
                          <span className="ms-3">Available Tickets</span>
                        </p> */}

                        {/* <p
                          className="fs-3 fw-bold text-start text-light"
                          data-aos="fade-down"
                          data-aos-delay="650"
                        >
                          <span className="number">
                            {eventData.EventData.soldTicket}
                          </span>
                          <span className="ms-3">Tickets Sold</span>
                        </p> */}
                      </>
                    ) : null}

                    {eventData.isBookingAvailable ? (
                      <Link
                        to={`../ticket-booking?id=${eventData.EventData.id}`}
                      >
                        <button
                          className="  btn rounded-4 btn-danger col-6 mt-3"
                          data-aos="fade-down"
                          data-aos-delay="600"
                        >
                          Book Now
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="  btn rounded-4 btn-danger col-8 mt-3"
                        data-aos="fade-down"
                        data-aos-delay="600"
                      >
                        Booking is not available now !
                      </button>
                    )}
                    <blockquote
                      className="blockquote mt-2"
                      data-aos="fade-down"
                      data-aos-delay="650"
                    >
                      <p className="mb-2">
                        EVENT DATE: {eventData.EventData.date}{" "}
                      </p>
                    </blockquote>

                    <div className="counter-wrap d-flex my-md-3">
                      <div className="text"></div>
                    </div>

                    <span className="mb-5 fs-4">Tickets</span>
                    <div
                      className="d-flex w-100 overflow-x-scroll scrollable-list"
                      data-aos="fade-down"
                      data-aos-delay="700"
                    >
                      {eventData.ticket.map((data: any, key: any) => (
                        <div
                          className="img col-8 img-about-2 mr-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleImageClick(
                              `${ticketImages[key]}`,
                              `${data.name}`,
                              `${data.price}`,
                              `${data.note ? data.note : ""}`
                            )
                          }
                          key={key}
                        >
                          <p>{data.name}</p>
                          <img
                            src={`${ticketImages[key]}`}
                            className="w-100 cursor-pointer"
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {/* <div className="container">
              <h2
                className="mb-4 mt-5 text-uppercase heading-section"
                data-aos="fade-down"
                data-aos-delay="400"
              >
                Artists
              </h2>

              <div className="container-fluid px-md-0">
                <div className="row no-gutters">
                  {eventData.artist.map((data: any, key: any) => (
                    <div
                      className="col-md-3 "
                      data-aos="fade-down"
                      data-aos-delay="300"
                      key={key}
                    >
                      <div
                        className="model model2 img d-flex align-items-end"
                        style={{ backgroundImage: `url(${artistImages[key]})` }}
                      >
                        <div className="desc w-100 px-4">
                          <div className="text w-100 mb-3">
                            <span>{data.name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            <div className="container">
              <h2
                className="mb-4 mt-5 text-uppercase heading-section"
                data-aos="fade-down"
                data-aos-delay="400"
              >
                Description
              </h2>
              <div  className="mb-4 mt-5 text-uppercase heading-section"
                data-aos="fade-down"
                data-aos-delay="500">{eventData.description}</div>
            </div>

            {/* <div className="container">
              <h2
                className="mb-4 mt-5 text-uppercase heading-section"
                data-aos="fade-down"
                data-aos-delay="400"
              >
                You can buy your tickets in here..
              </h2>
              <iframe
                className="w-100 mb-5"
                src={eventData.ticketLoaction}
                height="250"
                loading="lazy"
              ></iframe>
            </div> */}

            {/* <div className="container ">
              <h2
                className="mb-4 mt-5 text-uppercase heading-section"
                data-aos="fade-down"
                data-aos-delay="400"
              >
                sponsorship
              </h2>
              <div className="d-flex w-100 overflow-x-scroll scrollable-list">
                {eventData.sponsor.map((data: any, key: any) => (
                  <div className="mx-2" key={key}>
                    <img
                      src={`${sponsorImages[key]}`}
                      width={150}
                      height={150}
                      className="rounded-circle"
                      alt=""
                    />
                    <p className="text-center">
                      {data.title} <br /> {data.name}
                    </p>
                  </div>
                ))}
              </div>
            </div> */}

            {eventData.isPast ? (
              <section>
                <div className="container-fluid px-md-0 mt-5">
                  <div className="container">
                    <div className="row justify-content-start">
                      <div className="col-md-6 heading-section text-start pb-5">
                        <h2
                          className="mb-4 d-flex align-items-baseline"
                          data-aos="fade-down"
                          data-aos-delay="300"
                        >
                          <span className="me-3">Event Gallery</span>{" "}
                        </h2>
                        <p data-aos="fade-down" data-aos-delay="400">
                          A small river named Duden flows by their place and
                          supplies it with the necessary regelialia. It is a
                          paradisematic country.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row no-gutters">
                    {galleryImages.map((imageUrl, index) => (
                      <div
                        key={index}
                        className="col-md-4"
                        data-aos="fade-down"
                        data-aos-delay="300"
                        style={{ padding: "0px" }}
                      >
                        <div className="d-flex align-items-end">
                          <img
                            className="img-fluid"
                            src={imageUrl}
                            alt={`Gallery Image ${index}`}
                          />
                        </div>
                      </div>
                    ))}
                    <div
                      ref={ref}
                      style={{ height: "1px", width: "100%" }}
                    ></div>
                  </div>
                </div>
              </section>
            ) : null}
          </div>
        </section>
      ) : (
        <Loading />
      )}

      {/* Modal */}
      {showModal && (
        <ImageModal
          onOpen={showModal}
          imageUrl={selectedImage}
          onClose={handleCloseModal}
          note={selectedNote}
          name={selectedName}
          price={selectedPrice}
        />
      )}
    </>
  );
};
